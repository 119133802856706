import React from 'react'
import { Link } from 'gatsby'
import resolveLink from '@/utils/resolveLink'
import { Button as ButtonUI } from '@/components/ui/button'

const Button = ({
  children,
  className,
  to,
  target,
  onClick,
  activeClassName,
  before,
  variant = 'secondary_blue'
}) => {
  return (
    <ButtonUI
      before={before}
      variant={variant}
      onClick={onClick}
      className={className}
      to={to}
      target={target}
    >
      {children}
    </ButtonUI>
  )
  // const classes = `font-display relative inline-block h-[46px] cursor-pointer items-center overflow-hidden rounded-lg px-[27px] py-[13px] text-sm font-semibold tracking-[-0.5px] text-white transition-all duration-300 ease-in-out before:absolute before:-top-10 hover:leading-[9em] ${
  //     className ? className : ""
  // }`
  // return children ? (
  //     // If there is no target/link just output a div. This is to prevent the nested <a> problem
  //     !to ?
  //         <div
  //         className={`${classes}`}
  //         before={before}
  //         >
  //             {children}
  //         </div>
  //     // If the target is a non-gatsby target
  //     : to.includes('tel:') || to.includes('mailto:') || target === '_blank' ?
  //         <a
  //         className={`${classes}`}
  //         href={to}
  //         target={target}
  //         before={before}
  //         >
  //             {children}
  //         </a>
  //     // Default
  //     :
  //         <Link
  //         className={`${classes}`}
  //         to={resolveLink(to)}
  //         activeClassName={activeClassName ? activeClassName : ""}
  //         onClick={onClick ? onClick : ""}
  //         before={before}
  //         >
  //             {children}
  //         </Link>
  // )
  // : ''
}

export default Button
